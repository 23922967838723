const useAppBreakpoints = () => {
  const SCREEN_MD = '800px';
  const breakpoints = useBreakpoints({ md: SCREEN_MD });

  const {
    isDesktopOrTablet,
    isMobile,
    isTablet,
  } = useDevice();

  const isBpMobile = breakpoints.smaller('md');

  const isBpDesktop = breakpoints.greater('md');

  return {
    isBpDesktop,
    isBpMobile,
    isDesktopOrTablet,
    isMobile,
    isTablet,
  };
};

export { useAppBreakpoints };
